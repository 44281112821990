import { Injectable, Injector } from '@angular/core';

import { ObjectId, Profile } from '@mapuilabs/hv-interfaces';
import { CrudTemplateService } from '@shared/crud-service.class';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfilesCrudService extends CrudTemplateService<Profile> {
    /**
     * Constructor
     */
    constructor(_injector: Injector) {
        super(_injector, 'profiles', Profile);
    }

    public readByUserId(id: ObjectId): Observable<Profile[]> {
        return this._httpClient
            .get(`${this._endpoint}/user/${id.toString()}`)
            .pipe(map((profiles: Profile[]) => profiles.map((profile: Profile) => new Profile(profile))));
    }
}
